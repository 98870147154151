<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">班级列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级参数</a>
        </span>
      </div>
      <div class="framePage-body addClassSubject">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="flexcc">
              <el-form ref="Form" :model="Form" :rules="rules" label-width="14rem" :disabled="allDisabled">
                <el-form-item label="班级编码:" prop="projectCode" class="form-item">
                  <el-input v-model="Form.projectCode" disabled style="width: 250px" />
                </el-form-item>
                <el-form-item label="班级名称:" prop="projectName" class="form-item">
                  <el-input v-model="Form.projectName" disabled style="width: 250px" />
                </el-form-item>
                <el-form-item label="班级参数源:" class="form-item originalParameter">
                  <p style="color: red">{{ Form.originalParameter }}</p>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsLogin" class="form-item" label="实名认证必备条件">
                  <el-radio-group @change="handleclear()" v-model="Form.isIdCard"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">身份证照片</el-radio>
                    <el-radio :label="false">身份证号</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="idCardVerifyMode" class="form-item" v-if="!Form.isIdCard" label="验证方式">
                  <el-radio-group v-model="Form.idCardVerifyMode" :disabled="(Form.trainTypeId == '-1000' && Form.areaId == '-1000') || (['30','50','60'].includes(projectStudyTimeState))
                    ">
                    <el-radio :label="10">默认</el-radio>
                    <el-radio :label="20">公安认证</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsEventLimit" class="form-item" label="弹窗停留限制">
                  <el-radio-group v-model="Form.paraIsEventLimit" :disabled="(Form.trainTypeId == '-1000' && Form.areaId == '-1000') || (['30','50','60'].includes(projectStudyTimeState))
                    ">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="弹窗停留超过" v-if="Form.paraIsEventLimit" prop="paraIsEventLimitTime">
                  <el-input-number size="small" v-model="Form.paraIsEventLimitTime" :step="1" :min="1"
                    style="margin-right: 5px"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input-number>
                  <span>分钟后</span>
                </el-form-item>
                <el-form-item class="form-item" label="" v-if="Form.paraIsEventLimit" prop="paraIsEventLimitMode">
                  <el-radio-group v-model="Form.paraIsEventLimitMode" :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="10">返回上一级</el-radio>
                    <el-radio :label="20">原界面锁定</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsLoginEveryDay" class="form-item" label="每日学习签到">
                  <el-radio-group @change="handleclear()" v-model="Form.paraIsLoginEveryDay" :disabled="(Form.trainTypeId == '-1000' && Form.areaId == '-1000') ||
                    Form.paraIsRoll || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="Form.paraIsLoginEveryDay">
                  <el-form-item prop="paraIsLoginEveryDayMode" class="form-item" label="每日签到方式设置">
                    <el-radio-group v-model="Form.paraIsLoginEveryDayMode"
                      :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                      <el-radio :label="10">人脸</el-radio>
                      <el-radio :label="20">弹窗</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <template v-if="Form.paraIsLoginEveryDayMode == '20'">
                    <el-form-item prop="paraIsLoginEveryDayPopupFirst" class="form-item" label="是否第一次为人脸签到">
                      <el-radio-group v-model="Form.paraIsLoginEveryDayPopupFirst"
                        :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="提示语" class="form-item" prop="paraIsLoginEveryDayPopupMessage">
                      <el-input v-model="Form.paraIsLoginEveryDayPopupMessage" type="textarea" style="width: 15rem"
                        :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                    </el-form-item>
                  </template>
                </template>
                <el-divider></el-divider>
                <el-form-item prop="paraIsLogin" class="form-item" label="课件是否人脸签到">
                  <el-radio-group @change="handleclear()" v-model="Form.paraIsLogin"
                    :disabled="Form.paraIsRoll || projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsLoginEvery" v-if="Form.paraIsLogin == true" class="form-item"
                  label="课件未学完是否每次人脸签到">
                  <el-radio-group v-model="Form.paraIsLoginEvery"
                    :disabled="projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsLoginFinish" v-if="Form.paraIsLogin == true" class="form-item"
                  label="课件学完后是否人脸签到">
                  <el-radio-group v-model="Form.paraIsLoginFinish"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="Form.paraIsLogin">
                  <el-form-item prop="paraIsLoginMode" class="form-item" label="签到方式">
                    <el-radio-group v-model="Form.paraIsLoginMode"
                      :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                      <el-radio :label="10">人脸</el-radio>
                      <el-radio :label="20">弹窗</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <template v-if="Form.paraIsLoginMode == '10'">
                    <el-form-item prop="paraIsLoginBioassay" v-if="Form.paraIsLogin == true" class="form-item"
                      label="是否活体认证">
                      <el-radio-group v-model="Form.paraIsLoginBioassay"
                        :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </template>
                  <template v-if="Form.paraIsLoginMode == '20'">
                    <el-form-item label="提示语" class="form-item" prop="paraIsLoginPopupMessage">
                      <el-input v-model="Form.paraIsLoginPopupMessage" type="textarea" style="width: 15rem"
                        :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                    </el-form-item>
                  </template>
                </template>

                <el-divider></el-divider>
                <el-form-item prop="paraIsMiddle" class="form-item" label="课后习题是否签到">
                  <el-radio-group @change="handleclear()" v-model="Form.paraIsMiddle"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsMiddleEvery" v-if="Form.paraIsMiddle == true" class="form-item"
                  label="习题未通过是否每次签到">
                  <el-radio-group v-model="Form.paraIsMiddleEvery"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsMiddleBioassay" v-if="Form.paraIsMiddle == true" class="form-item"
                  label="是否活体认证">
                  <el-radio-group v-model="Form.paraIsMiddleBioassay"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsAfter" class="form-item" label="课程考试是否签到">
                  <el-radio-group @change="handleclear()" v-model="Form.paraIsAfter"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsAfterEvery" v-if="Form.paraIsAfter == true" class="form-item"
                  label="考试未通过是否每次签到">
                  <el-radio-group v-model="Form.paraIsAfterEvery"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsAfterBioassay" v-if="Form.paraIsAfter == true" class="form-item"
                  label="是否活体认证">
                  <el-radio-group v-model="Form.paraIsAfterBioassay"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="unitTestFace" class="form-item" label="单元测试是否签到">
                  <el-radio-group v-model="Form.unitTestFace" @change="handleclear()"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="unitTestFaceBioassay" class="form-item" label="是否活体认证" v-if="Form.unitTestFace">
                  <el-radio-group v-model="Form.unitTestFaceBioassay" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="allowDrag" class="form-item" label="视频进度条可拖拽">
                  <el-radio-group v-model="Form.allowDrag" @change="paraIsDragChange"
                    :disabled="projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsRoll" class="form-item" label="点名认证" v-show="!Form.allowDrag">
                  <el-radio-group v-model="Form.paraIsRoll" @change="paraIsRollChange"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraRollSecond" class="form-item" label="点名读秒" v-show="Form.paraIsRoll">
                  <el-input el-input size="small" style="width: 200px" v-model="Form.paraRollSecond"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                  <span style="margin-left: 10px">秒</span>
                </el-form-item>
                <el-form-item prop="paraRollOrgFailureNum" class="form-item" label="机构点名失败" v-show="Form.paraIsRoll">
                  <el-input size="small" style="width: 200px" v-model="Form.paraRollOrgFailureNum"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                  <span style="margin-left: 10px">次失去资格</span>
                </el-form-item>
                <el-form-item prop="paraRollGovFailureNum" class="form-item" label="监管点名失败" v-show="Form.paraIsRoll">
                  <el-input size="small" style="width: 200px" v-model="Form.paraRollGovFailureNum"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                  <span style="margin-left: 10px">次失去资格</span>
                </el-form-item>
                <el-form-item prop="paraRollRandomNum" class="form-item" label="课件随机点名次数" v-show="Form.paraIsRoll">
                  <el-input size="small" style="width: 200px" v-model="Form.paraRollRandomNum"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                  <span style="margin-left: 10px">次</span>
                </el-form-item>
                <el-form-item prop="paraRollVoiceRemind" class="form-item" label="声音提醒" v-show="Form.paraIsRoll">
                  <el-radio-group v-model="Form.paraRollVoiceRemind"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsRollBioassay" class="form-item" label="是否活体认证" v-show="Form.paraIsRoll">
                  <el-radio-group v-model="Form.paraIsRollBioassay"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsSnapshots" class="form-item" label="人脸识别防挂课" v-show="!Form.allowDrag">
                  <el-radio-group v-model="Form.paraIsSnapshots" @change="paraIsLoginTipsChange" :disabled="(Form.trainTypeId == '-1000' && Form.areaId == '-1000') ||
                    Form.paraIsRoll || projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraTimeMode" class="form-item" label="识别方式"
                  v-if="!Form.allowDrag && Form.paraIsSnapshots == true">
                  <el-radio-group v-model="Form.paraTimeMode" @change="paraTimeModeChange" :disabled="(Form.trainTypeId == '-1000' && Form.areaId == '-1000') ||
                    Form.paraIsRoll || projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio label="10">按课件</el-radio>
                    <el-radio label="20">按课程</el-radio>
                    <el-radio label="30">指定课件</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item prop="paraIsRandom" class="form-item"
                  v-if="Form.paraIsSnapshots == true && Form.paraTimeMode == '10'" label="人脸识别防挂课时长">
                  <el-radio-group @change="propclear"
                    :disabled="projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))"
                    v-model="Form.paraIsRandom">
                    <el-radio :label="true">随机时间</el-radio>
                    <el-radio :label="false">指定时长</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item class="form-item" v-if="Form.paraIsSnapshots == true &&
                  Form.paraIsRandom == true && Form.paraTimeMode == '10'
                  " label="最小时间" prop="paraRandomTimeMin">
                  <el-input-number size="small" v-model="Form.paraRandomTimeMin" :step="1" :min="1"
                    :disabled="projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))"
                    style="margin-right: 5px"></el-input-number>
                  <span>分钟</span>
                </el-form-item>
                <el-form-item class="form-item" v-if="Form.paraIsSnapshots == true &&
                  Form.paraIsRandom == true && Form.paraTimeMode == '10'
                  " label="随机次数" prop="paraRandomNum">
                  <el-input-number size="small" v-model="Form.paraRandomNum" :step="1" :min="1"
                    :disabled="projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))"
                    style="margin-right: 5px"></el-input-number>
                  <span>次</span>
                </el-form-item>
                <el-form-item class="form-item" prop="paraTime" v-if="Form.paraIsSnapshots == true &&
                  Form.paraIsRandom == false && Form.paraTimeMode == '10'
                  " label="最小时间">
                  <el-input-number v-model="Form.paraTime" :step="1" :min="1"
                    :disabled="projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))"
                    size="small"></el-input-number>
                </el-form-item>
                <el-form-item class="form-item" prop="paraTimeNum" v-if="Form.paraIsSnapshots == true &&
                  Form.paraIsRandom == false && Form.paraTimeMode == '10'
                  " label="最大次数">
                  <el-input-number v-model="Form.paraTimeNum" :step="1" :min="0" size="small" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    "></el-input-number>
                </el-form-item>
                <el-form-item class="form-item" prop="paraRandomFloatTime" v-if="Form.paraIsSnapshots == true &&
                  Form.paraIsRandom == false && Form.paraTimeMode == '10'
                  " label="浮动时间">
                  <el-input-number v-model="Form.paraRandomFloatTime" :step="1" :min="0" size="small" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    "></el-input-number>
                  <span>分</span>
                </el-form-item>
                <el-form-item class="form-item" prop="paraTimeNum" v-if="Form.paraIsSnapshots == true && Form.paraTimeMode == '20'
                  " label="认证次数">
                  <el-input-number v-model="Form.paraTimeNum" :step="1" :min="0" size="small" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    "></el-input-number>
                </el-form-item>
                <el-form-item class="form-item" v-if="Form.paraIsSnapshots == true && Form.paraTimeMode == '30'
                  ">
                  第
                  <el-input v-model="Form.paraTime" size="small" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    " style="width:200px"></el-input>课节，每节课
                  <el-input-number v-model="Form.paraTimeNum" :precision="0" :step="1" :min="1" :max="10" size="small"
                    :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || projectSource == '90' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                      "></el-input-number>
                  次认证
                  <p style=" color:red;">可以选多节课，逗号分隔开(应为逗号",")</p>
                </el-form-item>
                <el-form-item prop="paraIsSnapshotsBioassay" class="form-item" v-if="Form.paraIsSnapshots == true"
                  label="是否活体认证">
                  <el-radio-group v-model="Form.paraIsSnapshotsBioassay" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraRecordVideo" class="form-item" label="是否录制视频">
                  <el-radio-group v-model="Form.paraRecordVideo" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraRecordVideoMode" class="form-item" label="录制设置" v-if="Form.paraRecordVideo">
                  <el-radio-group v-model="Form.paraRecordVideoMode" @change="paraRecordVideoModeChange" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio :label="20">按课程</el-radio>
                    <el-radio :label="10">按课件</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraRecordVideoNode" class="form-item" v-if="Form.paraRecordVideo" label="录制时间点">
                  <el-radio-group @change="propclear" v-model="Form.paraRecordVideoNode" :disabled="(Form.trainTypeId == '-1000' && Form.areaId == '-1000') ||
                    Form.paraRecordVideoMode == '20' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio label="0">开始时录制</el-radio>
                    <el-radio label="4">1/4时</el-radio>
                    <el-radio label="3">1/3时</el-radio>
                    <el-radio label="2">1/2时</el-radio>
                    <el-radio label="-1">随机录制</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraRecordVideoTime" class="form-item" v-if="Form.paraRecordVideo == true"
                  label="录制时长">
                  <el-input-number v-model="Form.paraRecordVideoTime" :step="1" :min="5" :max="60" :precision="0"
                    size="small"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input-number>
                  <span style="margin-left: 10px">秒</span>
                </el-form-item>
                <el-form-item prop="paraRecordVideoRandomNum" class="form-item" v-if="Form.paraRecordVideo &&
                  Form.paraRecordVideoNode == '-1'
                  " label="录制次数">
                  <el-input-number v-model="Form.paraRecordVideoRandomNum" :step="1" :min="1" :precision="0" size="small"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input-number>
                  <span style="margin-left: 10px">次</span>
                </el-form-item>
                <el-divider></el-divider>
                <!-- 甘肃  弹窗是否点名 -->
                <el-form-item prop="popupApproveEnable" class="form-item" label="弹窗是否点名">
                  <el-radio-group v-model="Form.popupApproveEnable" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="popupApproveIsRandom" class="form-item" v-if="Form.popupApproveEnable"
                  label="点名时间点">
                  <el-radio-group v-model="Form.popupApproveIsRandom" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio :label="true">随机时间</el-radio>
                    <el-radio :label="false">指定时长</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item class="form-item" v-if="Form.popupApproveEnable &&
                  Form.popupApproveIsRandom
                  " label="最小时间" prop="popupApproveRandomTimeMin">
                  <el-input-number size="small" v-model="Form.popupApproveRandomTimeMin" :step="1" :min="1"
                    style="margin-right: 5px" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                      "></el-input-number>
                  <span>分钟</span>
                </el-form-item>
                <el-form-item class="form-item" v-if="Form.popupApproveEnable &&
                  Form.popupApproveIsRandom
                  " label="点名次数" prop="popupApproveRandomNum">
                  <el-input-number size="small" v-model="Form.popupApproveRandomNum" :step="1" :min="1"
                    style="margin-right: 5px" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                      "></el-input-number>
                  <span>次</span>
                </el-form-item>
                <el-form-item class="form-item" prop="popupApproveFixedTime" v-if="Form.popupApproveEnable == true &&
                  Form.popupApproveIsRandom == false
                  " label="间隔时间">
                  <el-input-number v-model="Form.popupApproveFixedTime" :step="1" :min="1" size="small" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    "></el-input-number>
                  <span>分钟</span>
                </el-form-item>
                <el-form-item class="form-item" prop="popupApproveFixedNum" v-if="Form.popupApproveEnable == true &&
                  Form.popupApproveIsRandom == false
                  " label="点名次数">
                  <el-input-number v-model="Form.popupApproveFixedNum" :step="1" :min="0" size="small" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    "></el-input-number>
                  <span>次</span>
                </el-form-item>
                <el-form-item class="form-item" v-if="Form.popupApproveEnable == true &&
                  Form.popupApproveIsRandom == false
                  " label="浮动时间" prop="popupApproveRandomFloatTime">
                  <el-input-number size="small" v-model="Form.popupApproveRandomFloatTime" :step="1" :min="0"
                    style="margin-right: 5px" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                      "></el-input-number>
                  <span>分钟</span>
                </el-form-item>
                <el-form-item label="提示语" class="form-item" prop="popupApproveHint" v-if="Form.popupApproveEnable">
                  <el-input v-model="Form.popupApproveHint" type="textarea" style="width: 15rem"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                </el-form-item>
                <el-divider></el-divider>
                <!-- 兰州  弹窗提示 -->
                <el-form-item prop="paraCountDownEnable" class="form-item" label="弹窗提示">
                  <el-radio-group v-model="Form.paraCountDownEnable"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item class="form-item" prop="paraCountDownDuration" v-if="Form.paraCountDownEnable"
                  label="弹窗提示停留时间">
                  <el-input-number v-model="Form.paraCountDownDuration" :step="1" :min="0" size="small" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    "></el-input-number>
                </el-form-item>
                <el-form-item label="提示语" class="form-item" prop="paraCountDownHint" v-if="Form.paraCountDownEnable">
                  <el-input v-model="Form.paraCountDownHint" type="textarea" style="width: 15rem"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsPaper" class="form-item" label="强制课后练习">
                  <el-radio-group v-model="Form.paraIsPaper"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsAnalysis" class="form-item" label="课后习题显示答题解析">
                  <el-radio-group v-model="Form.paraIsAnalysis"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsExamAnalysis" class="form-item" label="课程考试显示答题解析">
                  <el-radio-group v-model="Form.paraIsExamAnalysis"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraIsUnitTest" class="form-item" label="强制单元测试">
                  <el-radio-group v-model="Form.paraIsUnitTest"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="unitTestAnalysis" class="form-item" label="单元测试显示答案解析">
                  <el-radio-group v-model="Form.unitTestAnalysis"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraShowAnalysis" class="form-item" label-width="14rem" label="课后习题答题结果显示答案解析">
                  <el-radio-group v-model="Form.paraShowAnalysis"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="unitTestShowAnalysis" class="form-item" label-width="14rem" label="单元测试答题结果显示答案解析">
                  <el-radio-group v-model="Form.unitTestShowAnalysis"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="graduationShowAnalysis" class="form-item" label-width="14rem" label="结业考试答题结果显示答案解析">
                  <el-radio-group v-model="Form.graduationShowAnalysis"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraIsEvaluate" class="form-item" label="是否强制评价">
                  <el-radio-group v-model="Form.paraIsEvaluate"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="paraOrderStudy" class="form-item" label="按顺序学习">
                  <el-radio-group v-model="Form.paraOrderStudy"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <!-- <el-form-item
                  prop="paraCompleteCondition"
                  class="form-item"
                  label="特殊完成条件"
                >
                  <el-radio-group v-model="Form.paraCompleteCondition">
                    <el-radio :label="false">无</el-radio>
                    <el-radio :label="true">完成总视频时长</el-radio>
                  </el-radio-group>
                  <div
                    class="paraCompleteConditionInput"
                    v-if="Form.paraCompleteCondition"
                  >
                    <el-input
                      v-model="Form.paraCompleteConditionNum"
                      size="small"
                      clearable
                    />%
                  </div>
                  <div
                    style="font-size: 14px; color: red; margin-left: 70px"
                    v-if="Form.paraCompleteCondition"
                  >
                    *选择特殊完成条件的区域，请不要忘记修改该地区档案和学时证明模板
                  </div>
                </el-form-item> -->
                <el-form-item prop="paraCompleteConditionT" class="form-item" label="特殊完成条件">
                  <el-radio-group v-model="Form.paraCompleteConditionT" @change="paraCompleteConditionTChange"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="false">无</el-radio>
                    <el-radio :label="true">有</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraCompleteType" class="form-item" label="特殊完成类型"
                  v-if="Form.paraCompleteConditionT">
                  <el-radio-group v-model="Form.paraCompleteType"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio label="10">按视频时长</el-radio>
                    <el-radio label="20">按课时数量</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraCompleteConditionNum" class="form-item" label="特殊完成量"
                  v-if="Form.paraCompleteConditionT">
                  <el-input v-model="Form.paraCompleteConditionNum" size="small" style="width: 150px" clearable
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="每日学习时长限制">
                  <el-radio-group @change="handleclear()" v-model="Form.studyLength" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="学习时长限制类型" v-if="Form.studyLength">
                  <el-radio-group v-model="Form.xxsjlx" :disabled="Form.trainTypeId == '-1000' && Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                    ">
                    <el-radio :label="10">按课时</el-radio>
                    <el-radio :label="30">按小时</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div v-if="Form.studyLength">
                  <div class="df" v-for="(item, index) in Form.paraTimeLimit" :key="index">
                    <div style="display: flex">
                      <el-form ref="Form1" :model="item" label-width="13rem" style="
                          display: flex;
                          flex-direction: row;
                          flex-wrap: nowrap;
                        ">
                        <el-form-item class="form-item" label="人群类型名称" prop="personType" :rules="[
                          {
                            required: Form.xxsjlx == '30' ? true : false,
                            message: '请选择人群类型名称',
                            trigger: 'change',
                          },
                        ]">
                          <el-select v-model="item.personType" clearable placeholder="请选择" style="width: 150px"
                            size="small" :disabled="Form.trainTypeId == '-1000' &&
                              Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                              " @change="(val) => {
    changePersonType(val, index);
  }
    ">
                            <el-option v-for="item1 in LearningstateList" :key="item1.value" :label="item1.label"
                              :value="item1.value"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item class="form-item" label-width="0" style="padding-left: 0">
                          <div style="margin-left: 20px" class="flexac">
                            <span style="margin-right: 10px">每天学习</span>
                            <el-input-number size="small" v-model="item.timeLimit"
                              :precision="Form.xxsjlx == '30' ? 1 : 0" :step="1" :min="1" :disabled="Form.trainTypeId == '-1000' &&
                                Form.areaId == '-1000' || (classListCtrY && (['30','50','60'].includes(projectStudyTimeState)))
                                "></el-input-number>
                            <!-- <span style="margin: 0 23px 0 5px">课时</span> -->
                            <span style="margin: 0 23px 0 5px">{{
                              Form.xxsjlx == 10 ? "课时" : "小时"
                            }}</span>
                            <el-button v-if="Form.paraTimeLimit.length > 1" size="mini" @click="deleteItem(item, index)"
                              class="bgc-bv">删除</el-button>
                            <el-button v-if="index == Form.paraTimeLimit.length - 1" size="mini" @click="addItem"
                              class="bgc-bv" :disabled="Form.trainTypeId == '-1000' &&
                                Form.areaId == '-1000'
                                ">新增</el-button>
                          </div>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </div>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="考试次数是否限制">
                  <el-radio-group @change="handleclear()" v-model="Form.paraExamNumLimit">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="考试次数（次）" v-if="Form.paraExamNumLimit == true">
                  <el-input-number v-model="Form.paraExamMaxNum" :step="1" :min="1" size="small"
                    @change="changeParaExamMaxNum"></el-input-number>
                </el-form-item>
                <el-form-item label="提示语" class="form-item" v-if="Form.paraExamNumLimit == true" prop="paraExamHint">
                  <el-input v-model="Form.paraExamHint" type="textarea" style="width: 15rem"></el-input>
                </el-form-item>
                <el-form-item label="最大可购买补考次数" class="form-item" v-if="Form.paraExamNumLimit == true"
                  prop="paraExamHint">
                  <el-input-number v-model="Form.retakesAllowedNumber" :step="1" :min="0" size="small"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input-number>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="随机弹题">
                  <el-radio-group @change="handleparaIsPopQuestion" v-model="Form.paraIsPopQuestion"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="paraPopTimeLimit" class="form-item" label="弹题最小间隔时间" v-show="Form.paraIsPopQuestion">
                  <el-input-number v-model="Form.paraPopTimeLimit" :step="1" :min="1" :precision="0" size="small"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input-number>
                  <span style="margin-left: 10px">分钟</span>
                </el-form-item>
                <el-form-item prop="paraPopNum" class="form-item" label="每学时弹题次数" v-show="Form.paraIsPopQuestion">
                  <el-input-number v-model="Form.paraPopNum" :step="1" :min="1" :precision="0" size="small"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input-number>

                  <span style="margin-left: 10px">次</span>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否开启学习时间段限制">
                  <el-radio-group v-model="Form.paraStudyTime"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="增加学习时间段限制" v-show="Form.paraStudyTime">
                  <el-time-picker size="small" is-range arrow-control v-model="Form.studyTime" range-separator="至"
                    format="HH:mm" value-format="HH:mm:ss" :editable="false" start-placeholder="开始时间"
                    end-placeholder="结束时间" placeholder="选择时间范围"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                  </el-time-picker>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="班级培训结束后，允许学员继续观看视频">
                  <el-radio-group v-model="Form.paraFinishedWatch"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="观看天数" v-show="Form.paraFinishedWatch">
                  <el-input placeholder="请输入观看天数" v-model="Form.paraWatchDay" size="small" style="width: 200px"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <template slot="append">天</template>
                  </el-input>
                </el-form-item>
                <el-form-item class="form-item" label="是否启用字幕">
                  <el-radio-group v-model="Form.isShowSubtitle"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否需要签字">
                  <el-radio-group v-model="Form.paraSignature"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否开启弹窗管控">
                  <el-radio-group v-model="Form.enablePopupControls" @change="handlenablePopupControls"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="弹窗频次" v-show="Form.enablePopupControls">
                  <span style="margin-right: 10px">每</span>
                  <el-input-number size="small" v-model="Form.popupInterval" :step="1" :min="1"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input-number>
                  <span style="margin-left: 10px">分钟</span>
                </el-form-item>
                <el-form-item class="form-item" label="提示内容" v-show="Form.enablePopupControls">
                  <el-input v-model="Form.popupMessage" type="textarea" style="width: 15rem"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否启用弹幕">
                  <el-radio-group v-model="Form.enableBarrage"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="是否启用评论">
                  <el-radio-group v-model="Form.paraIsComment"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="讨论变更为答疑">
                  <el-radio-group v-model="Form.isDiscussChangeAnswerquestion"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="是否允许下载">
                  <el-radio-group v-model="Form.enableDownload"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="是否允许下载题库">
                  <el-radio-group v-model="Form.enableDownloadQuestionbank"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="暂停最大记录次数">
                  <el-input-number v-model="Form.paraSuspendNum" :step="1" :min="0" size="small"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input-number>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="自动生成截屏备案记录">
                  <el-radio-group v-model="Form.autoScreenShot" >
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" v-show="Form.autoScreenShot" label="生成方式">
                  <el-radio-group v-model="Form.screenShotMode">
                    <el-radio :label="10">随机生成</el-radio>
                    <el-radio :label="20">按培训时间每天生成</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" v-show="Form.autoScreenShot&&Form.screenShotMode==10" label="生成截屏文件数量">
                  <el-input-number v-model="Form.screenShotNum" :step="1" :min="0" size="small"></el-input-number>
                </el-form-item>
                <el-form-item class="form-item" v-show="Form.autoScreenShot" label="截屏时段">
                  <el-select v-model="Form.screenShotType" placeholder="请选择" style="width: 130px" size="small" >
                    <el-option v-for="item in ScreenList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="学习前上传证件照片">
                  <el-radio-group v-model="Form.isUploadIdPhoto"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否允许结业后考试">
                  <el-radio-group v-model="Form.paraIsGraduationAfterExam"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="结业考试限时">
                  <el-input style="width: 180px" placeholder="不输入默认结业考试不限时" v-model="Form.paraExamLimitTime"
                    @keyup.native="Form.paraExamLimitTime = zF.positiveInteger(Form.paraExamLimitTime)" size="small"
                    clearable
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                  分钟
                </el-form-item>
                <el-form-item class="form-item" label="结业考试交卷时间限制">
                  <el-input style="width: 180px" placeholder="不输入默认结业考试不限时" v-model="Form.paraExamLimitMinTime"
                    @keyup.native="Form.paraExamLimitMinTime = zF.positiveInteger(Form.paraExamLimitMinTime)" size="small"
                    clearable></el-input>
                  分钟
                </el-form-item>
                <el-form-item class="form-item" label="课后习题限时">
                  <el-input style="width: 180px" placeholder="不输入默认课后习题不限时" v-model="Form.paraAnalysisLimitTime"
                    @keyup.native="Form.paraAnalysisLimitTime = zF.positiveInteger(Form.paraAnalysisLimitTime)"
                    size="small" clearable
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                  分钟
                </el-form-item>
                <el-form-item class="form-item" label="课后练习交卷时间限制">
                  <el-input style="width: 180px" placeholder="不输入默课后练习试不限时" v-model="Form.paraAnalysisLimitMinTime"
                    @keyup.native="Form.paraAnalysisLimitMinTime = zF.positiveInteger(Form.paraAnalysisLimitMinTime)"
                    size="small" clearable></el-input>
                  分钟
                </el-form-item>
                <el-form-item class="form-item" label="单元测试限时">
                  <el-input style="width: 180px" placeholder="不输入默认单元测试不限时" v-model="Form.paraUnitTestLimitTime"
                    @keyup.native="Form.paraUnitTestLimitTime = zF.positiveInteger(Form.paraUnitTestLimitTime)"
                    size="small" clearable
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))"></el-input>
                  分钟
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="是否仅限本地IP学习">
                  <el-radio-group v-model="Form.paraIsLimitIp"
                    :disabled="classListCtrY && (['30','50','60'].includes(projectStudyTimeState))">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="档案学时设置">
                  
                  <el-radio-group v-model="Form.convertClassHourType"
                    :disabled="classListCtrY && (['30'].includes(projectStudyTimeState))">
                    <el-radio :label="'10'">按课件数量</el-radio>
                    <el-radio :label="'20'">按视频时长换算</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item class="form-item" label="档案课时计算方式" v-if="Form.convertClassHourType == '20'">
                  <el-radio-group v-model="Form.convertClassHour">
                    <el-radio v-for="(item,index) in convertClassHourList" :key="index" :label="item.value">{{ item.label }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
              </el-form>
            </div>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">返回</el-button>
              <el-button @click="seveData('ruleForm')" class="bgc-bv"
                v-if="!classListCtrY || (['30','50','60'].includes(projectStudyTimeState))">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "SupervisionEnd/addClassSubject",
  components: {
    // tree
  },
  mixins: [UploadPic, DatePicker],
  data() {
    var proTypeFrom = (rule, value, callback) => {
      var state = this.selectType;
      if (state == 2) {
        if (value == "") {
          callback(new Error("请选择人群类型"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入负责人电话"));
      } else {
        callback();
      }
    };
    return {
      id: "",
      stu: "",
      classListCtrY: "",
      projectStudyTimeState: "",
      LearningstateList: [], //人群类型
      Form: {
        projectCode: "",
        projectName: "",
        isIdCard: true,
        idCardVerifyMode: 10,
        paraIsLoginEveryDay: false,
        paraIsLoginEveryDayMode: 10,
        paraIsLoginEveryDayPopupFirst: false,
        paraIsLoginEveryDayPopupMessage: '根据上海监管要求，请确保为本人学习！',
        paraIsEventLimit: false,
        paraIsEventLimitTime: 10,
        paraIsEventLimitMode: 10,
        paraIsLoginMode: 10,
        paraIsLoginPopupMessage: false,
        paraIsLogin: false,
        paraIsLoginEvery: false,
        paraIsMiddle: false,
        paraIsMiddleEvery: false,
        paraIsAfter: false,
        paraIsAfterEvery: false,
        unitTestFace: false, // 单元测试是否签到
        paraIsRoll: false,
        paraRollSecond: "",
        paraRollOrgFailureNum: "",
        paraRollGovFailureNum: "",
        paraRollRandomNum: "2",
        paraRollVoiceRemind: false,
        paraIsSnapshots: false,

        paraTimeMode: '10',
        paraIsPaper: false,
        paraIsAnalysis: false,
        paraIsExamAnalysis: false,
        paraIsUnitTest: false, //强制单元测试
        unitTestAnalysis: false, //单元测试答案解析
        paraShowAnalysis: true,
        unitTestShowAnalysis: false,
        graduationShowAnalysis: false,
        paraIsEvaluate: false,
        paraOrderStudy: false,
        paraIsRandom: false,
        paraExamNumLimit: false,
        studyLength: false,
        paraTimeLimit: [],
        paraExamMaxNum: "2",
        paraRandomTimeMin: "",
        paraRandomNum: "",
        paraTime: "",
        paraRandomFloatTime: "",
        paraExamHint: "",
        obj: {
          personType: "",
          timeLimit: "",
        },
        // paraSpecialConditions: false,// 完成进度是否
        // paraProgress:'80' // 完成进度输入框默认值
        paraCompleteConditionT: false, //特殊完成条件
        paraCompleteType: "10",
        paraCompleteConditionNum: "80", //百分比
        paraIsPopQuestion: false, // 是否弹题
        paraPopTimeLimit: 10,
        paraPopNum: 3,
        allowDrag: false,
        paraFinishedWatch: false,
        paraWatchDay: "3",
        paraSignature: false,
        paraTimeNum: 0,
        enablePopupControls: false, //弹窗管控
        popupInterval: 5,
        popupMessage: "请认真学习课程内容",
        paraIsLoginBioassay: false, //课件是否人脸签到 活体认证
        paraIsMiddleBioassay: false, //习题是否签到  活体认证
        paraIsAfterBioassay: false, //课程考试是否签到  活体认证
        unitTestFaceBioassay: false, //单元测试是否签到  活体认证
        paraIsRollBioassay: false, // 视频进度条可拖拽 活体认证
        paraIsSnapshotsBioassay: false,
        paraRecordVideoMode: "20", // 录制设置
        paraRecordVideo: false,
        paraRecordVideoNode: "-1",
        paraRecordVideoTime: "5",
        paraRecordVideoRandomNum: "2",
        paraIsLoginFinish: false,
        enableBarrage: true,
        enableDownload: false,
        enableDownloadQuestionbank: false,
        //兰州参数   弹窗提示
        popupApproveEnable: false,
        popupApproveIsRandom: false,
        popupApproveRandomTimeMin: "",
        popupApproveRandomNum: false,
        popupApproveFixedTime: "",
        popupApproveFixedNum: false,

        popupApproveRandomFloatTime: "",
        popupApproveHint: "",
        paraCountDownEnable: false, //是否开启弹窗
        paraIsLanZhouCountDown: false, //是否开启弹窗倒计时
        paraCountDownDuration: "30", //开启弹窗倒计时
        paraCountDownHint: "", //提示语
        xxsjlx: 10, // 每日学习时长限制 - 学习时长限制类型
        retakesAllowedNumber: 0,
        paraSuspendNum: 0,
        paraIsComment: false,
        //截屏参数
        autoScreenShot: false,
        screenShotMode:20,
        screenShotNum: 1,
        screenShotType: 1,
        isUploadIdPhoto: false,
        paraIsGraduationAfterExam: false,
        isDiscussChangeAnswerquestion: false,
        isShowSubtitle: false, //是否启用字幕
        paraStudyTime: false,
        studyTime: ["00:00:00", "23:59:00"],
        paraAnalysisLimitTime: "", // 课后习题限时
        paraUnitTestLimitTime: "", // 单元测试限时
        paraExamLimitTime: "", // 结业考试限时
        paraExamLimitMinTime: "",
        paraAnalysisLimitMinTime: "",
        paraIsLimitIp: false,
        convertClassHourType:"10", // 档案学时设置
        convertClassHour: '45', // 档案课时计算方式
      },
      // ProgressDisabled: true, // 完成进度输入框禁用状态
      rules: {
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],

        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        isIdCard: [{ required: true, trigger: "change" }],
        idCardVerifyMode: [{ required: true, trigger: "change" }],
        authenticationBioassay: [{ required: true, trigger: "change" }],
        paraIsLoginEveryDay: [{ required: true, trigger: "change" }],
        paraIsLoginEveryDayMode: [{ required: true, trigger: "change" }],
        paraIsEventLimit: [{ required: true, trigger: "change" }],
        paraIsEventLimitTime: [{ required: true, trigger: "blur", message: "请输入弹窗停留时间" }],
        paraIsLoginEveryDayPopupFirst: [{ required: true, trigger: "change" }],
        paraIsLoginMode: [{ required: true, trigger: "change" }],
        paraIsLoginEveryDay: [{ required: true, trigger: "change" }],
        paraIsLogin: [{ required: true, trigger: "change" }],
        paraIsLoginBioassay: [{ required: true, trigger: "change" }],
        paraIsLoginEvery: [{ required: true, trigger: "change" }],
        paraIsMiddle: [{ required: true, trigger: "change" }],
        paraIsMiddleBioassay: [{ required: true, trigger: "change" }],
        paraIsMiddleEvery: [{ required: true, trigger: "change" }],
        paraIsAfter: [{ required: true, trigger: "change" }],
        paraIsAfterBioassay: [{ required: true, trigger: "change" }],
        paraIsAfterEvery: [{ required: true, trigger: "change" }],
        unitTestFace: [{ required: true, trigger: "change" }], //单元测试是否签到
        unitTestFaceBioassay: [{ required: true, trigger: "change" }], //单元测试是否签到
        paraIsSnapshotsBioassay: [{ required: true, trigger: "change" }],
        paraIsPaper: [{ required: true, trigger: "change" }],
        paraIsAnalysis: [{ required: true, trigger: "change" }],
        paraIsExamAnalysis: [{ required: true, trigger: "change" }],
        paraIsUnitTest: [{ required: true, trigger: "change" }], //强制单元测试
        unitTestAnalysis: [{ required: true, trigger: "change" }], //单元测试显示答案解析
        paraShowAnalysis: [{ required: true, trigger: "change" }],
        unitTestShowAnalysis: [{ required: true, trigger: "change" }],
        graduationShowAnalysis: [{ required: true, trigger: "change" }],
        paraIsEvaluate: [{ required: true, trigger: "change" }],
        paraOrderStudy: [{ required: true, trigger: "change" }],
        paraTimeLimit: [{ required: true, trigger: "change" }],
        paraIsRandom: [{ required: true, trigger: "change" }],
        paraIsRollBioassay: [{ required: true, trigger: "change" }],
        paraIsLoginFinish: [{ required: true, trigger: "change" }],
        enableBarrage: [{ required: true, trigger: "change" }],
        enableDownload: [{ required: true, trigger: "change" }],
        paraRecordVideo: [{ required: true, trigger: "change" }],
        /* 蘭州 */
        popupApproveEnable: [{ required: true, trigger: "change" }],
        paraCountDownEnable: [{ required: true, trigger: "change" }],
        paraIsSnapshots: [
          {
            required: true,
            trigger: "change",
            message: "请选择人脸识别防挂课时长",
          },
        ],
        paraTimeMode: [
          {
            required: true,
            trigger: "change",
            message: "请选择识别方式",
          },
        ],
        paraRandomNum: [
          { required: true, trigger: "blur", message: "请输入随机次数" },
        ],
        paraTime: [{ required: true, trigger: "blur", message: "请输入分钟" }],
        paraTimeNum: [
          { required: true, trigger: "blur", message: "请输入次数" },
        ],
        paraRandomFloatTime: [
          { required: true, trigger: "change", message: "请输入浮动时间" },
        ],
        paraRandomTimeMin: [
          { required: true, trigger: "blur", message: "请输入最小时间" },
        ],
        paraRollSecond: [{ validator: this.validatePass1, trigger: "blur" }],
        paraRollOrgFailureNum: [
          { validator: this.validatePass2, trigger: "blur" },
        ],
        paraRollGovFailureNum: [
          { validator: this.validatePass3, trigger: "blur" },
        ],
        paraRollRandomNum: [{ validator: this.validatePass4, trigger: "blur" }],
        paraCompleteConditionNum: [
          { validator: this.validatePass5, trigger: "change" },
        ],
        paraRecordVideoTime: [
          { required: true, trigger: "blur", message: "请输入录制时长" },
        ],
        paraRecordVideoRandomNum: [
          { required: true, trigger: "blur", message: "请输入录制次数" },
        ],
        //蘭州--弹窗倒计时
        paraCountDownDuration: [
          {
            required: true,
            trigger: "blur",
            message: "请输入弹窗提示停留时间",
          },
        ],
        paraCountDownHint: [
          {
            required: true,
            trigger: "blur",
            message: "请输入弹窗提示停留提示语",
          },
        ],
        paraIsLoginPopupMessage: [
          { required: true, trigger: "blur", message: "请输入提示语" },
        ],
        paraIsLoginEveryDayPopupMessage: [
          { required: true, trigger: "blur", message: "请输入提示语" },
        ],
        popupApproveHint: [
          { required: true, trigger: "blur", message: "请输入弹窗点名提示语" },
        ],
        popupApproveRandomTimeMin: [
          { required: true, trigger: "blur", message: "请输入最小时间" },
        ],
        popupApproveRandomNum: [
          { required: true, trigger: "blur", message: "请输入点名次数" },
        ],
        popupApproveFixedTime: [
          { required: true, trigger: "blur", message: "请输入间隔时间" },
        ],
        popupApproveFixedNum: [
          { required: true, trigger: "blur", message: "请输入点名次数" },
        ],

        popupApproveRandomFloatTime: [
          { required: true, trigger: "blur", message: "请输入浮动时间" },
        ],
      },
      // 截屏时段
      ScreenList: [],
      // 档案分钟转换课时数
      convertClassHourList: [],
      projectSource: '',
      allDisabled: "", // 全部字段只读
    };
  },
  created() {
    this.getLearningstateList();
    this.id = this.$route.query.projectId;
    this.stu = this.$route.query.stu;
    this.classListCtrY = this.$route.query.classListCtrY;
    this.projectStudyTimeState = this.$route.query.projectStudyTimeState;
    this.allDisabled = this.$route.query.allDisabled;
    if (this.$route.query.stu == "edit") {
      this.getDetail(this.id);
    } else {
      this.Form.paraTimeLimit.push({ ...this.Form.obj });
    }
  },
  watch: {
    // "Form.paraExamMaxNum"(newVal) {
    //   if (newVal) {
    //     this.Form.paraExamHint =
    //       "您有" + this.Form.paraExamMaxNum + "次考试机会，请认真答题！";
    //   }
    // },
  },
  methods: {
    changeParaExamMaxNum() {
      this.Form.paraExamHint =
        "您有" + this.Form.paraExamMaxNum + "次考试机会，请认真答题！";
    },
    validatePass1(rule, value, callback) {
      let reg = /^[1-9]?\d*$/;

      if (this.Form.paraIsRoll) {
        if (value === "") {
          callback(new Error("请输入点名读秒"));
        } else if (!reg.test(value) || Number(value) < 1) {
          callback(new Error("点名读秒必须大于等于1的正整数!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    validatePass2(rule, value, callback) {
      let reg = /^[1-9]?\d*$/;
      if (this.Form.paraIsRoll) {
        if (value === "") {
          callback(new Error("请输入机构点名失败几次失去资格"));
        } else if (!reg.test(value) || Number(value) < 1) {
          callback(new Error("次数必须大于等于1的正整数!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    validatePass3(rule, value, callback) {
      let reg = /^[1-9]?\d*$/;
      if (this.Form.paraIsRoll) {
        if (value === "") {
          callback(new Error("请输入监管点名失败几次失去资格"));
        } else if (!reg.test(value) || Number(value) < 1) {
          callback(new Error("次数必须大于等于1的正整数!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    validatePass4(rule, value, callback) {
      let reg = /^[1-9]?\d*$/;
      if (this.Form.paraIsRoll) {
        if (value === "") {
          callback(new Error("请输入课件随机点名次数"));
        } else if (!reg.test(value) || Number(value) < 1) {
          callback(new Error("次数必须大于等于1的正整数!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    validatePass5(rule, value, callback) {
      let reg = /^(([1-9]\d{1})||\d{1})(\.\d{1})?$/;
      if (this.Form.paraCompleteConditionT) {
        if (this.Form.paraCompleteConditionNum == "") {
          callback(new Error("请输入完成视频时长的百分比"));
        } else if (!reg.test(this.Form.paraCompleteConditionNum)) {
          callback(new Error("请输入0~100之间数值，支持1位小数"));
        } else if (this.Form.paraCompleteConditionNum == 0) {
          callback(new Error("请输入0~100之间数值，支持1位小数"));
        }
        {
          callback();
        }
      } else {
        this.Form.paraCompleteConditionNum = "80";
        callback();
      }
    },
    paraIsLoginTipsChange(val) {
      if (val == false) {
        this.Form.paraRandomTimeMin = "";
        this.Form.paraRandomNum = "";
        this.Form.paraTime = "";
        this.Form.paraIsRandom = false;
      }
      this.Form.paraTimeMode = '10'
    },
    paraTimeModeChange(val) {
      if (val == '20') {
        this.Form.paraTimeNum = "3";
      }
      if(val == '30'){
        this.Form.paraTimeNum = "5";
      }
    },
    paraIsRollChange(val) {
      if (val == false) {
        this.Form.paraRollSecond = "";
        this.Form.paraRollOrgFailureNum = "";
        this.Form.paraRollGovFailureNum = "";
        this.Form.paraRollRandomNum = "";
        (this.Form.voice = false),
          this.$refs["Form"].clearValidate("paraRollSecond");
        this.$refs["Form"].clearValidate("paraRollOrgFailureNum");
        this.$refs["Form"].clearValidate("paraRollGovFailureNum");
        this.$refs["Form"].clearValidate("paraRollRandomNum");
      } else {

        this.Form.paraIsLoginEveryDay = false;
        this.Form.paraIsLogin = false;
        this.Form.paraIsLoginEvery = false;
        this.Form.paraIsLoginFinish = false;

        this.Form.paraIsSnapshots = false;
      }
    },
    paraIsDragChange(val) {
      if (val == true) {
        this.Form.paraIsRoll = false;
        this.Form.paraIsSnapshots = false;
        this.Form.paraRollSecond = "";
        this.Form.paraRollOrgFailureNum = "";
        this.Form.paraRollGovFailureNum = "";
        this.Form.paraRollRandomNum = "";
        (this.Form.voice = false),
          this.$refs["Form"].clearValidate("paraRollSecond");
        this.$refs["Form"].clearValidate("paraRollOrgFailureNum");
        this.$refs["Form"].clearValidate("paraRollGovFailureNum");
        this.$refs["Form"].clearValidate("paraRollRandomNum");
      }
    },
    paraRecordVideoModeChange(val) {
      if (val == "20") {
        this.Form.paraRecordVideoNode = "-1";
      }
    },
    paraCompleteConditionTChange(val) {
      if (val == false) {
        this.Form.paraCompleteConditionNum = "";
        this.Form.paraCompleteType = "";
      } else {
        this.Form.paraCompleteConditionNum = "80";
        this.Form.paraCompleteType = "10";
      }
    },
    // 获取 - 字典
    getLearningstateList() {
      // 人群类型
      for (const key in this.$setDictionary("CROWDTYPE", "list")) {
        this.LearningstateList.push({
          value: key,
          label: this.$setDictionary("CROWDTYPE", "list")[key],
        });
      }
      // 截屏时段
      for (const key in this.$setDictionary("PROJECT_PARAMETER_SCREEN_SHOT_TIME", "list")) {
        this.ScreenList.push({
          value: key,
          label: this.$setDictionary("PROJECT_PARAMETER_SCREEN_SHOT_TIME", "list")[key],
        });
      }
      // 档案分钟转换课时数
      for (const key in this.$setDictionary("CONVERT_CLASS_HOUE", "list")) {
        this.convertClassHourList.push({
          value: key,
          label: this.$setDictionary("CONVERT_CLASS_HOUE", "list")[key],
        });
      }
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (valid) {
          if (this.Form.studyLength) {
            if (this.check1()) {
              this.doAjax();
            }
          } else {
            this.doAjax();
          }
        }
      });
    },
    //校验学员类型
    check1() {
      const cardform = this.$refs["Form1"];
      let stu = true;
      for (let i = 0; i < this.Form.paraTimeLimit.length; i++) {
        cardform[i].validate((valid1) => {
          if (valid1 == false) {
            stu = false;
          }
        });
      }
      return stu;
    },
    //删除人群类型名称
    deleteItem(item, index) {
      this.Form.paraTimeLimit.splice(index, 1);
    },
    //新增人群类型名称
    addItem() {
      this.Form.paraTimeLimit.push({ ...this.Form.obj });
    },
    propclear() {
      this.$refs.Form.clearValidate("paraIsSnapshots");
      this.$refs.Form.clearValidate("paraRandomNum");
      this.$refs.Form.clearValidate("paraTime");
      this.Form.paraRandomTimeMin = "";
      this.Form.paraRandomNum = "";
      this.Form.paraTime = "";
    },
    // 点否时清除 之前选是时 下面的数据
    handleclear() {
      if (this.Form.paraIsLogin == false) {
        this.Form.paraIsLoginEvery = false;
        this.Form.paraIsLoginFinish = false;
        this.Form.paraIsLoginBioassay = false;
        this.Form.paraIsLoginMode = 10;
        this.Form.paraIsLoginPopupMessage = '根据上海监管要求，请确保为本人学习！';
      }
      if (this.Form.paraIsMiddle == false) {
        this.Form.paraIsMiddleEvery = false;
        this.Form.paraIsMiddleBioassay = false;
      }
      if (this.Form.paraIsAfter == false) {
        this.Form.paraIsAfterEvery = false;
        this.Form.paraIsAfterBioassay = false;
      }
      if (this.Form.unitTestFace == false) {
        this.Form.unitTestFaceBioassay = false;
      }
      if (this.Form.paraExamNumLimit == false) {
        this.Form.paraExamMaxNum = "";
        this.Form.paraExamHint = "";
      } else {
        this.Form.paraExamMaxNum = "2";
        this.Form.paraExamHint = "您有2次考试机会，请认真答题！";
      }
      if (this.Form.studyLength == false) {
        this.Form.paraTimeLimit = [{}];
      }
      if (this.Form.paraIsPopQuestion == false) {
        this.Form.paraPopTimeLimit = "";
        this.Form.paraPopNum = "";
      }
      if (this.Form.paraIsLoginEveryDay == false) {
        this.Form.paraIsLoginEveryDayMode = 10;
        this.Form.paraIsLoginEveryDayPopupFirst = false;
        this.Form.paraIsLoginEveryDayPopupMessage = '根据上海监管要求，请确保为本人学习！';
      }
    },
    handlenablePopupControls() {
      if (this.Form.enablePopupControls == false) {
        this.Form.popupInterval = "";
        this.Form.popupMessage = "";
      }
      if (this.Form.enablePopupControls == true) {
        this.Form.popupInterval = 5;
        this.Form.popupMessage = "请认真学习课程内容";
      }
    },
    doAjax() {
      let parmar = {
        isIdCard: this.Form.isIdCard,
        paraExamHint: this.Form.paraExamHint,
        paraExamMaxNum: this.Form.paraExamMaxNum,
        paraExamNumLimit: this.Form.paraExamNumLimit,
        paraId: this.Form.paraId,
        paraIsAfter: this.Form.paraIsAfter,
        paraIsAfterEvery: this.Form.paraIsAfter == false ? false : this.Form.paraIsAfterEvery,
        unitTestFace: this.Form.unitTestFace, //单元测试是否签到
        paraIsAnalysis: this.Form.paraIsAnalysis,
        paraIsExamAnalysis: this.Form.paraIsExamAnalysis,
        paraIsUnitTest: this.Form.paraIsUnitTest, //强制单元测试
        unitTestAnalysis: this.Form.unitTestAnalysis, //单元测试答案解析
        paraShowAnalysis: this.Form.paraShowAnalysis, //单元测试答案解析
        unitTestShowAnalysis: this.Form.unitTestShowAnalysis, //单元测试答案解析
        graduationShowAnalysis: this.Form.graduationShowAnalysis, //单元测试答案解析
        paraIsEvaluate: this.Form.paraIsEvaluate,

        paraIsLoginEveryDay: this.Form.paraIsLoginEveryDay,
        paraIsLogin: this.Form.paraIsLogin,
        paraIsLoginEvery:
          this.Form.paraIsLogin == false ? false : this.Form.paraIsLoginEvery,
        paraIsLoginFinish: this.Form.paraIsLoginFinish,
        paraIsMiddle: this.Form.paraIsMiddle,
        paraIsMiddleEvery: this.Form.paraIsMiddleEvery,
        paraIsPaper: this.Form.paraIsPaper,
        paraIsRandom: this.Form.paraIsRandom,
        paraIsLoginTips: false,
        paraOrderStudy: this.Form.paraOrderStudy,
        paraIsSnapshots: this.Form.paraIsSnapshots,

        paraTimeMode: this.Form.paraTimeMode,
        trainTypeId: this.Form.trainTypeId,
        // paraRandomTimeMin: this.Form.paraRandomTimeMin,
        // paraRandomNum: this.Form.paraRandomNum,
        paraTime: this.Form.paraTime,
        paraRandomFloatTime: this.Form.paraRandomFloatTime,
        paraTimeNum: this.Form.paraTimeNum,
        paraFinishedWatch: this.Form.paraFinishedWatch,
        paraWatchDay: this.Form.paraFinishedWatch ? this.Form.paraWatchDay : "",
        paraSignature: this.Form.paraSignature,
        enablePopupControls: this.Form.enablePopupControls, //弹窗管控
        popupInterval: this.Form.popupInterval,
        popupMessage: this.Form.popupMessage,
        paraIsAfterBioassay: this.Form.paraIsAfterBioassay,
        paraIsLoginBioassay: this.Form.paraIsLoginBioassay,
        paraIsMiddleBioassay: this.Form.paraIsMiddleBioassay,
        unitTestFaceBioassay: this.Form.unitTestFaceBioassay,
        paraIsRollBioassay: this.Form.paraIsRollBioassay,
        paraIsSnapshotsBioassay: this.Form.paraIsSnapshotsBioassay,
        paraRecordVideo: this.Form.paraRecordVideo,
        paraRecordVideoMode: this.Form.paraRecordVideoMode,
        paraRecordVideoNode: this.Form.paraRecordVideoNode,
        paraRecordVideoTime: this.Form.paraRecordVideoTime,
        paraRecordVideoRandomNum:
          this.Form.paraRecordVideoNode == "-1"
            ? this.Form.paraRecordVideoRandomNum
            : "1",
        enableBarrage: this.Form.enableBarrage,
        paraIsComment: this.Form.paraIsComment,
        enableDownload: this.Form.enableDownload,
        enableDownloadQuestionbank: this.Form.enableDownloadQuestionbank,
        popupApproveEnable: this.Form.popupApproveEnable,
        paraCountDownEnable: this.Form.paraCountDownEnable,
        retakesAllowedNumber: this.Form.retakesAllowedNumber,
        paraSuspendNum: this.Form.paraSuspendNum,
        autoScreenShot: this.Form.autoScreenShot,
        screenShotMode: this.Form.screenShotMode,
        isUploadIdPhoto: this.Form.isUploadIdPhoto,
        paraIsGraduationAfterExam: this.Form.paraIsGraduationAfterExam,
        isDiscussChangeAnswerquestion: this.Form.isDiscussChangeAnswerquestion,
        isShowSubtitle: this.Form.isShowSubtitle,
        convertClassHourType: this.Form.convertClassHourType, // 档案学时设置
        convertClassHour: this.Form.convertClassHour, // 档案课时计算方式
        paraIsLimitIp: this.Form.paraIsLimitIp,
        paraIsLoginEveryDayMode: this.Form.paraIsLoginEveryDayMode,
        paraIsLoginEveryDayPopupFirst: this.Form.paraIsLoginEveryDayPopupFirst,
        paraIsLoginEveryDayPopupMessage: this.Form.paraIsLoginEveryDayPopupMessage,
        paraIsLoginMode: this.Form.paraIsLoginMode,
        paraIsLoginPopupMessage: this.Form.paraIsLoginPopupMessage,
        paraIsEventLimit: this.Form.paraIsEventLimit,
        paraIsEventLimitTime: this.Form.paraIsEventLimitTime ?? 10,
        paraIsEventLimitMode: this.Form.paraIsEventLimitMode ?? 10,
        idCardVerifyMode: this.Form.idCardVerifyMode,
      };
      if (this.Form.paraIsPopQuestion) {
        parmar.paraIsPopQuestion = this.Form.paraIsPopQuestion;
        parmar.paraPopTimeLimit = this.Form.paraPopTimeLimit;
        parmar.paraPopNum = this.Form.paraPopNum;
      } else {
        parmar.paraIsPopQuestion = this.Form.paraIsPopQuestion;
        parmar.paraPopTimeLimit = "";
        parmar.paraPopNum = "";
      }
      if (this.Form.allowDrag) {
        parmar.allowDrag = this.Form.allowDrag;
      } else {
        if (this.Form.paraIsRoll == true) {
          parmar.paraIsRoll = this.Form.paraIsRoll;
          parmar.paraRollOrgFailureNum = this.Form.paraRollOrgFailureNum;
          parmar.paraRollGovFailureNum = this.Form.paraRollGovFailureNum;
          parmar.paraRollRandomNum = this.Form.paraRollRandomNum;
          parmar.paraRollSecond = this.Form.paraRollSecond;
          parmar.paraRollVoiceRemind = this.Form.paraRollVoiceRemind;
        } else {
          parmar.paraIsRoll = this.Form.paraIsRoll;
          parmar.paraRollOrgFailureNum = this.Form.paraRollOrgFailureNum;
          parmar.paraRollGovFailureNum = this.Form.paraRollGovFailureNum;
          parmar.paraRollRandomNum = "";
          parmar.paraRollSecond = this.Form.paraRollSecond;
          parmar.paraRollVoiceRemind = this.Form.paraRollVoiceRemind;
        }
        if (this.Form.paraIsRandom == true) {
          parmar.paraRandomTimeMin = this.Form.paraRandomTimeMin;
          parmar.paraRandomNum = this.Form.paraRandomNum;
        } else {
          parmar.paraRandomTimeMin = "";
          parmar.paraRandomNum = "";
        }
      }
      if (this.Form.paraCompleteConditionT) {
        parmar.paraCompleteCondition = this.Form.paraCompleteConditionNum;
        parmar.paraCompleteType = this.Form.paraCompleteType;
      }
      if (this.Form.studyLength == false) {
        parmar.paraTimeLimit = "[{}]";
      } else {
        // 学习时长限制类型：10-按课时；30-按小时
        parmar.studyTimeLimitType = this.Form.xxsjlx;
        parmar.paraTimeLimit = JSON.stringify(this.Form.paraTimeLimit);
      }
      if (this.Form.areaId) {
        parmar.areaId = this.Form.areaId;
      }
      if (this.stu == "edit") {
        parmar.projectId = this.id;
      }
      //兰州点名参数
      if (this.Form.popupApproveEnable) {
        parmar.popupApproveIsRandom = this.Form.popupApproveIsRandom;
        parmar.popupApproveHint = this.Form.popupApproveHint;
        if (this.Form.popupApproveIsRandom) {
          parmar.popupApproveRandomTimeMin =
            this.Form.popupApproveRandomTimeMin;
          parmar.popupApproveRandomNum = this.Form.popupApproveRandomNum;
        } else {
          parmar.popupApproveFixedTime = this.Form.popupApproveFixedTime;
          parmar.popupApproveFixedNum = this.Form.popupApproveFixedNum;
          parmar.popupApproveRandomFloatTime = this.Form.popupApproveRandomFloatTime;
        }
      } else {
        parmar.popupApproveIsRandom = this.Form.popupApproveIsRandom;
        parmar.popupApproveHint = "";
        if (this.Form.popupApproveIsRandom) {
          parmar.popupApproveRandomTimeMin = "";
          parmar.popupApproveRandomNum = "";
        } else {
          parmar.popupApproveFixedTime = "";
          parmar.popupApproveFixedNum = "";
          parmar.popupApproveRandomFloatTime = "";
        }
      }
      if (this.Form.paraCountDownEnable) {
        parmar.paraCountDownDuration = this.Form.paraCountDownDuration;
        parmar.paraCountDownHint = this.Form.paraCountDownHint;
      }
      if (this.Form.autoScreenShot) {
        parmar.screenShotNum = this.Form.screenShotNum;
        parmar.screenShotType = this.Form.screenShotType;
        parmar.screenShotMode = this.Form.screenShotMode;
      } else {
        parmar.screenShotNum = "5";
        parmar.screenShotType = "1";
        parmar.screenShotMode = 20;
      }
      if (this.Form.paraStudyTime) {
        parmar.studyStartTime = this.Form.studyTime[0].slice(0, 5) + ':00';
        parmar.studyEndTime = this.Form.studyTime[1].slice(0, 5) + ':00';
      } else {
        parmar.studyStartTime = "";
        parmar.studyEndTime = "";
      }
      if (this.Form.paraExamLimitTime) {
        parmar.paraExamLimitTime = this.Form.paraExamLimitTime;
      }
      if (this.Form.paraExamLimitMinTime) {
        parmar.paraExamLimitMinTime = this.Form.paraExamLimitMinTime;
      }
      if (this.Form.paraAnalysisLimitMinTime) {
        parmar.paraAnalysisLimitMinTime = this.Form.paraAnalysisLimitMinTime;
      }
      if (this.Form.paraAnalysisLimitTime) {
        parmar.paraAnalysisLimitTime = this.Form.paraAnalysisLimitTime;
      }
      if (this.Form.paraUnitTestLimitTime) {
        parmar.paraUnitTestLimitTime = this.Form.paraUnitTestLimitTime;
      }
      this.$post(
        "/projectCourseParam/edu-project-course-parameter-private/insert",
        parmar
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            if (this.classListCtrY) {
              setTimeout(() => {
                this.$router.push({
                  path: "/web/classListCtrY",
                  query: {
                    refrsh: true,
                  },
                });
              }, 300);
            } else {
              setTimeout(() => {
                this.$router.push({
                  path: "/web/classListClassparameterY",
                  query: {
                    refrsh: true,
                  },
                });
              }, 300);
            }
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.back();
    },
    getDetail(projectId) {
      this.$post(
        "/projectCourseParam/edu-project-course-parameter-private/getInfo",
        { projectId }
      ).then((res) => {
        if (res.status == "0") {
          this.projectSource = res.data.projectSource;
          this.Form = {
            ...res.data.eduProjectCourseParameterPrivate,
            //  新增的
            paraIsPopQuestion:
              res.data.eduProjectCourseParameterPrivate.paraIsPopQuestion ||
              false,
            paraPopTimeLimit:
              res.data.eduProjectCourseParameterPrivate.paraPopTimeLimit ||
              "10",
            paraPopNum:
              res.data.eduProjectCourseParameterPrivate.paraPopNum || "3",
            //
            paraTimeLimit: JSON.parse(
              res.data.eduProjectCourseParameterPrivate.paraTimeLimit || "[]"
            ),
            studyLength:
              res.data.eduProjectCourseParameterPrivate.paraTimeLimit !== "[{}]"
                ? true
                : false,
            paraCompleteConditionT:
              res.data.eduProjectCourseParameterPrivate.paraCompleteCondition ==
                null ||
                res.data.eduProjectCourseParameterPrivate.paraCompleteCondition <=
                0
                ? false
                : true,
            paraCompleteType:
              res.data.eduProjectCourseParameterPrivate.paraCompleteType,

            paraCompleteConditionNum: res.data.eduProjectCourseParameterPrivate
              .paraCompleteCondition
              ? res.data.eduProjectCourseParameterPrivate.paraCompleteCondition
              : "80",
            projectCode: res.data.projectCode,
            projectName: res.data.projectName,
            originalParameter:
              (res.data.originalParameter.areaId == "-1000"
                ? "默认"
                : res.data.originalParameter.areaNamePath) +
              "-" +
              (res.data.originalParameter.trainTypeId == "-1000"
                ? "默认"
                : res.data.originalParameter.trainTypeNamePath),
            screenShotType: res.data.eduProjectCourseParameterPrivate
              .screenShotType
              ? res.data.eduProjectCourseParameterPrivate.screenShotType.toString()
              : "1",
            screenShotNum: res.data.eduProjectCourseParameterPrivate
              .screenShotNum
              ? res.data.eduProjectCourseParameterPrivate.screenShotNum
              : "5",
            xxsjlx:
              res.data.eduProjectCourseParameterPrivate.studyTimeLimitType,
            paraStudyTime: res.data.eduProjectCourseParameterPrivate.studyStartTime ? true : false,
            studyTime: res.data.eduProjectCourseParameterPrivate.studyStartTime ? [res.data.eduProjectCourseParameterPrivate.studyStartTime, res.data.eduProjectCourseParameterPrivate.studyEndTime] : ['00:00:00', '23:59:00'],

            paraExamNumLimit:
              res.data.eduProjectCourseParameterPrivate.paraExamNumLimit ||
              false,
            paraExamMaxNum:
              res.data.eduProjectCourseParameterPrivate.paraExamMaxNum,
            paraExamHint:
              res.data.eduProjectCourseParameterPrivate.paraExamHint,
            retakesAllowedNumber:
              res.data.eduProjectCourseParameterPrivate.retakesAllowedNumber,
            paraIsLoginEveryDayMode: res.data.eduProjectCourseParameterPrivate.paraIsLoginEveryDayMode,
            paraIsLoginEveryDayPopupFirst: res.data.eduProjectCourseParameterPrivate.paraIsLoginEveryDayPopupFirst,
            paraIsLoginEveryDayPopupMessage: res.data.eduProjectCourseParameterPrivate.paraIsLoginEveryDayPopupMessage,
            paraIsLoginMode: res.data.eduProjectCourseParameterPrivate.paraIsLoginMode,
            paraIsLoginPopupMessage: res.data.eduProjectCourseParameterPrivate.paraIsLoginPopupMessage,
            paraIsEventLimit: res.data.eduProjectCourseParameterPrivate.paraIsEventLimit,
            paraIsEventLimitTime: res.data.eduProjectCourseParameterPrivate.paraIsEventLimitTime,
            paraIsEventLimitMode: res.data.eduProjectCourseParameterPrivate.paraIsEventLimitMode,
            idCardVerifyMode: res.data.eduProjectCourseParameterPrivate.idCardVerifyMode,
            convertClassHourType: String(res.data.eduProjectCourseParameterPrivate.convertClassHourType) || "10",
            convertClassHour: String(res.data.eduProjectCourseParameterPrivate.convertClassHour) || "45",
          };
          if (res.data.eduProjectCourseParameterPrivate.paraIsSnapshots && !res.data.eduProjectCourseParameterPrivate.paraTimeMode) {
            this.Form.paraTimeMode = '10'
          } else if (res.data.eduProjectCourseParameterPrivate.paraIsSnapshots && res.data.eduProjectCourseParameterPrivate.paraTimeMode) {
            this.Form.paraTimeMode = '' + res.data.eduProjectCourseParameterPrivate.paraTimeMode
          }
          // if (res.data.eduProjectCourseParameterPrivate.screenShotType) {
          //   this.Form.screenShotType = res.data.eduProjectCourseParameterPrivate.screenShotType.toString()
          // }
          //   if (!this.Form.eduProjectCourseParameterPrivate.paraTimeLimit.length) {
          //     this.Form.eduProjectCourseParameterPrivate.paraTimeLimit.push({ ...this.Form.obj });
          //   }
        }
      });
    },
    /* 特殊完成条件Change事件 */
    // handleSpecialConditions(val) {
    //   this.Form.paraSpecialConditions = val
    //   if(this.Form.paraSpecialConditions == false) {
    //     this.ProgressDisabled = true;
    //     this.Form.paraProgress = '80';
    //   } else {
    //       this.ProgressDisabled = false;
    //   }
    // }
  },
};
</script>
<style lang="less" scoped>
.addClassSubject {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-item {
      width: 100%;
      padding-left: 10rem;
    }

    .padd0 {
      padding-left: 0;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-textarea {
    .el-textarea__inner {
      resize: none;
    }
  }

  .paraCompleteConditionInput {
    display: inline-block;

    .el-input {
      display: inline-block;
      width: 40% !important;
      margin: 0 10px;
      height: 32px;
    }
  }
}

.originalParameter {
  .el-form-item__label {
    color: red;
  }
}
</style>